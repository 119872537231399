.program-div {
  background: linear-gradient(145deg, #3498db, #3498db3b);
  width: 90vw;
  border-radius: 10px;
  margin-top: 30px;
  padding: 20px;
  transition: background-color ease-in-out 1s;
}

.program-div:hover {
  background-color: #c8c1cf;
}

.programs-img-div {
  width: 70vw;
  height: 60vw;
  overflow: hidden;
  background-color: transparent;
}

.programs-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.program-title {
  border: 2px solid #8e44ad;
  padding: 6px 40px;
  border-radius: 40px;
  background-color: #ffffff;
}

.program-desc-div {
  border: 2px solid #8e44ad;
  padding: 10px;
  border-radius: 40px;
  background-color: #ffffff;
}

.program-heading {
  font-size: 120%;
}

.program-text {
  font-size: 90%;
  font-weight: 600;
  line-height: 1.4rem;
}

@media (min-width: 1024px) {
  .tech-career {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }

  .program-div {
    width: 40vw;
    margin-top: 15px;
    margin-bottom: 50px;
    padding: 0px 20px;
    height: 60vw;
  }

  .programs-img-div {
    width: 25vw;
    height: 25vw;
  }

  .programs-img2 {
    margin-top: 10px;
    margin-bottom: 38px;
  }

  .program-title {
    font-size: 1.5rem;
  }

  .program-heading {
    font-size: 1.5rem;
  }

  .program-text {
    font-size: 100%;
    font-weight: 600;
    line-height: 1.8rem;
  }

  .program-desc-div {
    height: 27vw;
  }
}
