.dashbord-back-main {
  text-align: center;
  width: 100vw;
  background-color: #f3f3fb;
}

.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #f3f3fb;
}

.side-bar {
  background-color:#00afef;
  height: 100vh;
  width: 20vw;
  position: fixed;
  left: 0;
  padding-left: 0px;
  z-index: 100;
}

.dashboard-back-btn {
  padding: 10px;
  font-size: 0.6rem;
}

.dashboard-heading {
  margin-bottom: -5px;
  font-size: 0.7rem;
  font-weight: 600;
}

.side-bar ul {
  list-style: none;
  display: flex;
  gap: 10px;
  margin: 0;
  padding: 0;
  margin-top: 40px;
  font-weight: 600;
  width: 100%;
}

.side-bar ul li button {
  font-size: 0.6rem;
  padding: 12px;
  padding-left: 10px;
  width: 100%;
  padding: 10px;
  display: block;
  border: none;
  background-color: #00afef;
  color: #ffffff;
  font-weight: 600;
  outline: none;
  cursor: pointer;
}

.side-bar ul li button.active {
  color: #fff212;
  font-weight: 600;
  display: block;
  width: 100%;
}

/* COMPONENTS */
.dashboard-component {
  text-align: center;
  width: 100vw;
  margin-left: 0vw;
  padding-left: 18vw;
  padding-top: 20px;
  background-color: #f3f3fb;
  background-position: fixed;
  z-index: 2;
}

.dashboard-component-heading {
  margin-top: 20px;
  display: inline;
  background-color: #ecf0f1;
  background: linear-gradient(45deg, #b49eca, #ecf0f1);
  color: #8e44ad;
  padding: 15px;
  border-radius: 80% 40% 100% 30% / 90% 50% 70% 35%;
  z-index: 100;
}

.component-body {
  margin-top: 40px;
  margin-left: 3vw;
}

.component-text {
  line-height: 25px;
  text-align: center;
}

.support-form {
  padding-right: 30px;
}

.support-textarea {
  width: 100%;
  height: 40vw;
  background-color: #ffffff;
  border: none;
  border-left: 6px solid #8e44ad;
  padding: 20px;
  z-index: 100;
  outline: none;
  font-size: 0.8rem;
  display: flex;
  z-index: 100;
  margin: 10px 15px;
}

.support-textarea::placeholder {
  font-size: 0.6rem;
}

.dashboard-component-btn {
  margin: 20px auto;
  color: #ffffff;
  background-color: #8e44ad;
  padding: 10px;
  border: none;
  border-radius: 10px;
  font-size: 0.8rem;
  transition: background-color 0.3s, color 0.3s;
}

.info-item {
  background-color: #ffffff;
  padding: 10px;
  text-align: left;
  margin: 10px 15px;
  border-left: 6px solid #8e44ad;
  border-bottom: none;
}

.info-item:first-child {
  margin-top: 40px;
}

@media (min-width: 1024px) {
  .side-bar {
    width: 15vw;
  }

  .dashboard-back-btn {
    font-size: 0.9rem;
  }
  
  .dashboard-heading {
    margin-bottom: -5px;
    font-size: 1.4rem;
    font-weight: 900;
  }

  .side-bar ul li button {
    font-size: 1.2rem;
  }

  .info-item {
    padding: 16px;
    margin: 10px 15px;
    margin-right: 4vw;
  }

  .support-form {
    padding-right: 75px;
  }

  .dashboard-component-btn {
    padding: 10px 20px;
    font-size: 1rem;
  }

  .support-textarea {
    height: 15vw;
    margin: 10px 15px;
    margin-right: 6vw;
    font-size: 1rem;
  }

  .support-textarea::placeholder {
    font-size: 0.8rem;
  }  
}

@media (min-width: 768px) and (max-width: 1023px) {
  .info-item {
    margin-left: 30px;
  }

  .support-form {
    padding-right: 50px;
  }

  .support-textarea {
    margin-left: 30px;
  }
}