.landing-page-main {
  padding: 10px;
  margin-bottom: 40px;
}

.section-title {
  font-size: 1.2rem;
  margin-top: 50px;
}

.rule {
  height: 2px;
  width: 160px;
  background-color:#3498db;
  margin-top: -20px;
}

.rule1 {
  height: 2px;
  width: 80px;
  background-color:#8e44ad;
  margin-top: -20px;
}

@media (min-width: 1024px) {
  .section-title {
    font-size: 2rem;
    margin-top: 80px;
  }

  .rule1 {
    margin-bottom: 45px;
    margin-top: -40px;
  }

  .rule {
    margin-top: -20px;
  }
}