.desk-none {
  display: block;
}

.mob-none {
  display: none;
}

.navbar {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  /* z-index: 1000; */
  display: inline-flex;
}

.nav-back {
  width: 100vw;
  height: 60px;
  background-color: #ffffff;
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  display: flex;
  align-items: flex-end;
  z-index: 1;
}

.nav-back.change {
  display: none;
}

#menu {
    z-index: 2;
  }
  
  #menu-bar {
    width: 45px;
    /* height: 40px; */
    margin: 10px 0 10px 10px;
    cursor: pointer;
  }
  
  .bars {
    background-color: #8e44ad;
    padding: 10px;
    padding-right: 3px;
    border-radius: 5px;
    border: #ecf0f1 1px solid;
  }
  
  .bar {
    height: 5px;
    width: 80%;
    background-color: #ecf0f1;
    display: block;
    border-radius: 5px;
    transition: 0.3s ease;
    z-index: 10;
  }
  
  #bar1 {
    transform: translateY(-4px);
  }
  
  #bar3 {
    transform: translateY(4px);
  }

  .nav-logo {
    width: 80px;
    height: 30px;
    position: fixed;
    top: 10px;
    right: 10px;
  }

  .nav-logo.change {
    display: none;
  }
  
  .nav {
    transition: 0.3s ease;
    display: none;
  }
  
  .nav ul {
    padding: 0 22px;
  }
  
  .nav li {
    list-style: none;
    padding: 12px 0;
  }
  
  .nav li a {
    font-size: 20px;
    text-decoration: none;
    font-weight: 700;
    color: #ffffff;
    z-index: 10;
    transition: color ease-in-out 0.5s;
  }
  
  .nav li a:hover {
    color: #b49eca;
  }
  
  .menu-bg, #menu {
    top: 0;
    left: 0;
    position: absolute;
  }
  
  .menu-bg {
    z-index: -10;
    width: 0;
    height: 0;
    margin: 30px 0 20px 20px;
    background: radial-gradient(circle, #3498db, #b49eca, #8e44ad);
    border-radius: 50%;
    transition: 0.3s ease;
  }
  
  .change {
    display: block;
  }
  
  .navbar.change {
    background-color: transparent;
    width: 100%;
    height: 80px;
  }
  
  .bars.change {
    background-color: transparent;
    border: none;
  }
  
   .bar.change {
    background-color: #ffffff;
  }
  
  #bar1.change {
    transform: translateY(4px) rotateZ(-45deg);
    background-color: white;
  }
  
  #bar2.change {
    opacity: 0;
  }
  
  #bar3.change {
    transform: translateY(-6px) rotateZ(45deg);
    background-color: white;
  }
  
  .change-bg {
    width: 520px;
    height: 460px;
    transform: translate(-60%, -30%);
  }

  .nav-panel-logo {
    width: 35vw;
  }

  .overlay1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    opacity: 0.85;
    z-index: -999;
  }
  
  .no-scroll {
    overflow: hidden;
  }

  @media (min-width: 1024px) {
    .desk-none {
      display: none;
    }

    .mob-none {
      display: flex;
    }

    .nav-logo {
      width: 120px;
      height: 40px;
      position: fixed;
      top: 5px;
      left: 10px;
    }
  
    .nav-links-desk {
      position: fixed;
      top: 18px;
      right: 60px;
      display: flex;
      align-items: center;
      gap: 60px;
    }

    .nav-join {
      padding: 5px 20px;
      background-color: #3498db;
      color: #ffffff;
      font-weight: 700;
      border-radius: 20px;
    }
  }
