.logout-div {
  margin-left: 18vw;
  justify-content: space-between;
  width: 75%;
}

.logout-div p {
  font-size: 0.8rem;
}

.logout-btn {
  border: none;
  background-color: transparent;
  font-size: 0.6rem;
  gap: 5px;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .logout-div {
    margin-left: 14vw;
  }

  .logout-div p {
    font-size: 1.4rem;
  }

  .logout-btn {
    font-size: 0.9rem;
    gap: 10px;
  }
}
