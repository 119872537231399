.video-background {
  position: relative;
  width: 100%;
  height: 75vh;
  overflow: hidden;
  margin-bottom: -60px;
  background-color: #f5f5f5;
}


.b-p-circle {
  width: 90px;
  float: right;
  margin-top: 50px;
  margin-bottom: -50px;
}

.w-y-circle {
  width: 70px;
  float: left;
  margin-top: 80px;
}

.b-circle-dots {
  width: 55px;
  margin-top: 55vh;
  margin-left: -50px;
}

.content {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
}

.main-logo {
  width: 55vw;
  margin-top: -120px;
}

.main-desc {
  width: 90vw;
  font-size: 110%;
  margin-top: 30px;
}

.get-started {
  background-color: #8e44ad;
  color: #ffffff;
  font-size: 1rem;
  padding: 10px 14px;
  border-radius: 10px;
  border: none;
  margin-top: 30px;
  display: block;
}

.stripes {
  margin: 0px -20px;
  margin-bottom: 20px;
  rotate: -15deg;
}

.first-stripes {
  width: 100%;
  height: 35px;
}

.first-stripes div:nth-child(1){
  width: 50%;
  background-color: rgb(255, 255, 143);
}

.first-stripes div:nth-child(2){
  width: 30%;
  background-color: #f5f5f5;
}

.first-stripes div:nth-child(3){
  width: 20%;
  background-color: #c277e2;
}

.second-stripes {
  width: 100%;
  height: 35px;
}

.second-stripes div:nth-child(1){
  width: 33.3%;
  background-color: #6fbff5;
}

.second-stripes div:nth-child(2){
  width: 33.3%;
  background-color: #f5f5f5;
}

.second-stripes div:nth-child(3){
  width: 33.3%;
  background-color: #6fbff5;
}

.third-stripes {
  width: 100%;
  height: 35px;
}

.third-stripes div:nth-child(1){
  width: 20%;
  background-color: #c277e2; 
}

.third-stripes div:nth-child(2){
  width: 30%;
  background-color: #ffffff;
}

.third-stripes div:nth-child(3){
  width: 50%;
  background-color: rgb(255, 255, 143);
}

@media (min-width: 1024px) {
  .video-background {
    position: relative;
    height: 100vh;
    overflow: hidden;
  }
  
  .video-background::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -50%;
    width: 200%;
    height: 200px;
    background: linear-gradient(to right bottom, transparent 50%, transparent 50%);
    transform: skewY(0deg);
    z-index: -1;
  }

  .b-p-circle {
    width: 15%;
  }
  
  .w-y-circle {
    width: 12%;
  }

  .b-circle-dots {
    width: 75px;
    margin-top: 65vh;
    margin-left: -70px;
  }
  
  .main-logo {
    margin-top: -200px;
    width: 30vw;
  }
  
  .main-desc {
    margin-top: 60px;
    width: 70vw;
    font-size: 200%;
  }
  
  .get-started {
    font-size: 1rem;
    padding: 16px;
    width: 10vw;
    display: flex;
    justify-content: center;
  }

  .stripes {
    position: relative;
    margin: -110px -30px;
    margin-top: -80px;
    margin-bottom: 50px;
    rotate: -4deg;
  }
  
  .first-stripes {
    width: 100%;
    height: 45px;
  }
  
  .first-stripes div:nth-child(1){
    width: 50%;
    background-color: rgb(255, 255, 143);
  }
  
  .first-stripes div:nth-child(2){
    width: 30%;
    background-color: #f5f5f5;
  }
  
  .first-stripes div:nth-child(3){
    width: 20%;
    background-color: #c277e2;
  }
  
  .second-stripes {
    width: 100%;
    height: 45px;
  }
  
  .second-stripes div:nth-child(1){
    width: 33.3%;
    background-color: #6fbff5;
  }
  
  .second-stripes div:nth-child(2){
    width: 33.3%;
    background-color: #f5f5f5;
  }
  
  .second-stripes div:nth-child(3){
    width: 33.3%;
    background-color: #6fbff5;
  }
  
  .third-stripes {
    width: 100%;
    height: 45px;
  }
  
  .third-stripes div:nth-child(1){
    width: 20%;
    background-color: #c277e2; 
  }
  
  .third-stripes div:nth-child(2){
    width: 30%;
    background-color: #ffffff;
  }
  
  .third-stripes div:nth-child(3){
    width: 50%;
    background-color: rgb(255, 255, 143);
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .stripes {
    position: relative;
    margin: -60px -30px;
    margin-bottom: 50px;
    rotate: -4deg;
  }
}
