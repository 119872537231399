.progress-title {
    text-align: left;
    margin-left: 15px;
}

.progress-title:first-child {
    margin-top: 25px;
}

.progress-item {
    background-color: #ffffff;
    padding: 10px;
    text-align: left;
    margin: 10px 15px;
    border-left: 6px solid #8e44ad;
}

.dashboard-component.progress-back {
    position: relative;
  }

  .collapsible-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    background-color: #ffffff;
    text-align: left;
    margin: 10px 15px;
    border-left: 6px solid #8e44ad;
    margin-top: -10px;
  }

  .collapsible-content ul {
    list-style: none;
    padding: 0px 10px;
  }

  .collapsible-content ul li {
    margin-bottom: 10px;
    font-size: 90%;
  }
  
  .collapsible-content.open {
    max-height: 1000px;
  }

  .fa-angle-right {
    transition: transform 0.3s;
  }

  .fa-angle-right.open {
    transform: rotate(90deg);
  }

  .lesson-day {
    padding-left: 10px;
  }
  
@media (min-width: 1024px) {
    .progress-item {
        padding: 16px;
        margin: 10px 15px;
        margin-right: 4vw;
      }

      .collapsible-content {
        margin: 10px 15px;
        margin-right: 4vw;
        margin-top: -10px;
      }

      .collapsible-content ul li {
        margin-bottom: 10px;
        font-size: 100%;
        line-height: 150%;
      }

      .collapsible-content.open {
        max-height: 1400px;
      }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .progress-title {
        margin-left: 30px;
    }

    .progress-item {
      margin-left: 30px;
    }

    .collapsible-content {
        margin-left: 30px;
      }
  }