@media (min-width: 1024px) {
.scroll-to-top-button {
    background-color: #fbff14;
    color: black;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 20px;
    right: 56px;
    outline: none;
    font-size: 120%;
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
}