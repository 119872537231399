.footer {
  display: flex;
  flex-direction: column;
  background-color: #443466;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.footer-subheading {
  font-size: 1.2rem;
  color: #ffffff;
}

.footer-links a {
  margin-bottom: 15px;
  transition: scale ease-in-out 0.5s;
  color: yellow;
}

.footer-links a:hover {
  scale: 0.9;
}

.footer-logo {
  width: 130px;
  margin: 20px 0px;
}
.footer-socials {
  padding: 10px;
}

.socials-div {
  padding: 10px;
}

.footer-social {
  background-color: #ffffff;
  background: linear-gradient(45deg, #a9c3d4, #ffffff);
  color: #3498db;
  padding: 5px;
  border-radius: 50%;
  margin: 5px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: scale ease-in-out 0.5s;
}

.footer-social:hover {
  scale: 0.9;
}

.social {
  width: 8vw;
}

.copyrights {
  display: flex;
  justify-content: center;
  align-items: end;
  color: #ffffff;
  font-size: 0.6rem;
  text-align: center;
}

@media (min-width: 1024px) {
  .footer-div {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-evenly;
    width: 100%;
  }

  .footer-text {
    display: flex;
    flex-wrap: wrap;
  }
  
  .social {
    width: 2.5vw;
  }
}