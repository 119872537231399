.join-form-container {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 60px;
  min-height: 100vh; 
}

.wyc {
  position: absolute;
  width: 60px;
  margin-top: 10px;
  left: 0;
}

.bpc {
  position: absolute;
  width: 65px;
  right: 0;
}

.join-page-statement-div {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.join-page-statement {
  margin-top: 50px;
  width: 65vw;
  text-align: center;
  font-weight: 700;
}

.join-page-statement span {
  color: #22a7ff;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.purple-check {
  margin-left: -5px;
  width: 35px;
}

.join-pic-div {
  width: 250px;
  height: 60vw;
  overflow: hidden;
  background-color: transparent;
}

.join-pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.star-purple {
  position: absolute;
  width: 20px;
  left: 20px;
  top: 40vh;
}

.star-blue {
  position: absolute;
  width: 20px;
  right: 10px;
  bottom: 16vh;
}

.tools-section {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tools-heading {
  text-align: center;
  font-weight: 700;
}

.tools {
  width: 90%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 12vw;
}

.tool-logo {
  width: 60px;
}

.tool-logo-special {
  width: 50px;
  margin-top: 10px;
}

.tool-name {
  font-size: 0.8rem;
  font-weight: 700;
  margin-top: -1px;
}

.single-view-page {
  overflow: hidden;
}

.popup1 {
  background: #ecf0f1c2;
}

.popup-content1 {
  background-color: #ecf0f1be;
}

.popup-close1 {
  color: #0a2a3f;
}

@media (min-width: 1024px) { 
  .wyc {
    width: 12%;
  }
  
  .bpc {
    width: 15%;
  }

  .purple-check {
    margin-left: -10px;
    width: 55px;
  }
  
  .star-purple {
    width: 40px;
    top: 60vh;
  }
  
  .star-blue {
    width: 40px;
    right: 40px;
  }

  .join-pic-div {
    width: 20% !important;
    height: 16vw;
    margin: auto;
  }

  .join-pic {
    object-fit: cover;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .join-pic-div {
    width: 40% !important;
    height: 25vw;
    margin: auto;
    margin-bottom: 20px;
  }
}
