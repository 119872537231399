.handbook-list {
  text-align: left;
  padding: 20px;
  list-style: lower-alpha;
  background-color: #f3f3fb;
}

.handbook-back {
  min-height: 100%;
}

@media (min-width: 1024px) {
  .handbook-list {
    padding: 20px 40px;
  }
}