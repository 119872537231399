.about-page {
  text-align: center;
  background-color: #f7f7f7;
  padding: 30px 16px;
  padding-top: 80px;
}

.dots-star {
  width: 90px;
  float: left;
}

.star-dots {
  width: 90px;
  float: right;
  margin-top: -20px;
}

.about-contents {
  margin: 40px 0px;
  padding: 30px 16px;
  background-color: #ffffff;
  border-radius: 20px;
  line-height: 1.4rem;
  font-size: 120%;
  /* font-weight: 600; */
}

.about-pic-div {
  background-color: #f7f7f7;
  padding: 16px;
  /* border-radius: 10px; */
}

.one {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.two {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.about-pic-container {
  width: 100%;
  padding: auto 0;
  height: 50vw;
  overflow: hidden;
  background-color: transparent;
  margin-bottom: 20px;
}

.about-pic {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.about-pic-div p {
  line-height: 1rem;
  margin-top: -5px;
}

.about-pic-div p:nth-child(2) {
  margin-top: 15px;
  margin-bottom: -10px;
}

.about-pic-div p:nth-child(3) {
  margin-top: 15px;
}

.about-pic-div p:nth-child(4) {
  margin-top: -10px;
}

.about-join-us {
  background-color: #3498db;
  color: #ffffff;
  font-size: 0.8rem;
  font-weight: 700;
  padding: 8px 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

@media (min-width: 1024px) {
  .dots-star {
    width: 150px;
    float: left;
  }
  
  .star-dots {
    width: 150px;
    float: right;
    margin-top: -20px;
  }

  .about-contents {
    margin: 70px 50px;
    padding: 30px 120px;
    background-color: #ffffff;
    border-radius: 20px;
    line-height: 1.4rem;
    font-size: 120%;
  }

  .about-pic-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
    padding: 20px;
    gap: 5%;
  }

  .about-pic-div p {
    line-height: 1.4rem;
    margin-top: 0px;
  }

  .about-pic-div p:nth-child(1) {
    margin-top: 15px;
    margin-bottom: -10px;
    font-size: 80%;
  }

  .about-pic-div p:nth-child(2) {
    margin-top: 45px;
    margin-bottom: -10px;
    font-size: 120%;
  }
  
  .about-pic-div p:nth-child(3) {
    margin-top: 15px;
    font-size: 80%;
  }
  
  .about-pic-div p:nth-child(4) {
    margin-top: -10px;
    font-size: 80%;
  }

  .about-pic-container {
    width: 35%;
    height: 25vw;
  }

  .about-pic-content {
    width: 65%;
  }
}