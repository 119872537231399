body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  color: #121a22;
  /* border: 1px solid red; */
}

a {
  text-decoration: none;
}

.purple {
  color: #8e44ad;
}

.light-purple {
  color: #b49eca;
}

.blue {
  color: #3498db;
}

.light-blue {
  color: #a9c3d4;
}

.light-grey {
  color: #ecf0f1;
}

.white {
  color: #ffffff;
}

.red {
  color: #c00000;
}

.green {
  color: rgb(10, 94, 75);
}

.yellow {
  color: #fff212;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.align-center {
  display: flex;
  align-items: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.bold {
  font-weight: 900;
}

.caps {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.size1 {
  font-size: 1.5rem;
}

.size2 {
  font-size: 1.2rem;
}

.size3 {
  font-size: 1rem;
}

.size4 {
  font-size: 80%;
}

.size5 {
  font-size: 70%;
}
.mob-none {
  display: none;
}

.desktop-none {
  display: block;
}

@media (min-width: 1024px) {
  .size4 {
    font-size: 1rem;
  }

  .mob-none {
    display: block;
  }
  
  .desktop-none {
    display: none;
  }
  
}

@keyframes rotateLetter {
  from {
      transform: rotateY(0deg);
  }
  to {
      transform: rotateY(360deg);
  }
}