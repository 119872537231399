.category {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 90vw;
  height: 60vw;
  border-radius: 10px;
  display: flex;
  margin-top: 15px;
  box-shadow: 0 16px 20px -10px rgba(0, 0, 0, 0.5);
  transition: scale ease-in-out 1s, opacity ease-in-out 1s;
}

.category:hover {
  scale: 1.02;
  opacity: 0.8;
}

.eligibility {
  border-top-left-radius: 40px;
  justify-content: end;
  align-items: end;
}

.eligibility button {
  background-color: #8e44ad;
  border: none;
  padding: 16px;
  font-size: 1rem;
  border-top-left-radius: 20px;
  outline: none;
  transition: background-color ease-in-out 1s;
  cursor: pointer;
}

.eligibility button:hover {
  background-color: #3498db;
}

.calendar {
  border-top-right-radius: 40px;
  justify-content: start;
  align-items: end;
}

.calendar button {
  background-color: #8e44ad;
  border: none;
  padding: 16px;
  font-size: 1rem;
  border-top-right-radius: 20px;
  outline: none;
  transition: background-color ease-in-out 1s;
  cursor: pointer;
}

.calendar button:hover {
  background-color: #3498db;
}

.languages {
  border-top-left-radius: 40px;
  justify-content: end;
  align-items: end;
}

.languages button {
  background-color: #8e44ad;
  border: none;
  padding: 16px;
  font-size: 1rem;
  border-top-left-radius: 20px;
  outline: none;
  transition: background-color ease-in-out 1s;
  cursor: pointer;
}

.languages button:hover {
  background-color: #3498db;
}

.curriculum {
  border-top-right-radius: 40px;
  justify-content: start;
  align-items: end;
}

.curriculum button {
  background-color: #8e44ad;
  border: none;
  padding: 16px;
  font-size: 1rem;
  border-top-right-radius: 20px;
  outline: none;
  transition: background-color ease-in-out 1s;
  cursor: pointer;
}

.curriculum button:hover {
  background-color: #3498db;
}

/* POPUP */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #121a22;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: color-burn;
  background-color: #121a22c9;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  transition: transform 2s ease, opacity 2s ease;
  opacity: 0;
  animation: fadeInOut 2s forwards;
}

.popup-content.show {
  opacity: 1;
}

.popup-close {
  color: #fff;
  float: right;
  font-size: 2.4rem;
  margin-right: 20px;
  margin-top: 15px;
  z-index: 1000;
  transition: transform 0.5s, color 0.5s;
  cursor: pointer;
}

.popup-close:hover {
  color: #8e44ad;
  transform: rotate(90deg);
}

.body-no-scroll {
  overflow: hidden;
}

.popup-heading {
  margin-left: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8e44ad;
  padding: 30px;
  width: 100%;
  background-image: url('../images/paint_brush.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.eligibility-contents {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.popup-component {
  padding: 10px;
}

.popup-list {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: -10px 0px;
}

.popup-list p {
  color: #fff;
  font-weight: 600;
}

.list-tick {
  width: 20px;
  height: 16px;
}

.popup-tools {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.more-info {
  padding: 0 10px;
}

.more-info p {
  font-size: 0.8rem;
  color: #fff;
  margin: 0;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 1024px) {
  .categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: -60px;
    margin-bottom: 40px;
  }

  .category {
    width: 40vw;
    height: 20vw;
  }

  .section-title-desk {
    font-size: 1.4rem;
    margin-top: 60px;
  }

  /* POPUP */
  .popup-heading {
    font-size: 1.4rem;
  }

  .popup-component {
    padding-top: 10px;
    display: flex;
    justify-content: center;
    margin: auto;
  }

  .list-tick {
    width: 32px;
    height: 24px;
  }
  
  .popup-list {
    display: flex;
    align-items: center;
    gap: 30px;
    margin: -10px 0px;
  }
  
  .popup-list p {
    color: #fff;
    font-weight: 600;
    font-size: 1.1rem;
  }

  .popup-tools {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .more-info {
    padding: 0 80px;
  }
  
  .more-info p {
    font-size: 1rem;
    color: #fff;
    margin: 0;
  }
}
