.landing-page {
  text-align: center;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.landing {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.back-link {
  float: left;
  font-size: 0.8rem;
  margin-top: 20px;
  color: #ecf0f1;
}

.back-link:hover {
  scale: 1.01;
}

.fa-arrow-left:hover {
  scale: 1.01;
}

.signin-page {
  perspective: 1000px;
  transition: perspective 1s;
}

.signin {
  background-color: #b49ecac9;
  border-radius: 15px;
  margin: auto;
  width: 80%;
  /* backface-visibility: hidden; */
  transform-style: preserve-3d;
  transition: transform 1s;
}

.register {
  background-color: #a9c3d4c9;
  border-radius: 15px;
  margin: auto;
  width: 80%;
  /* backface-visibility: hidden; */
  transform-style: preserve-3d;
  transition: transform 1s;
}

.signin-vision {
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline;
  font-size: 1rem;
  background-color: #ecf0f1;
  background: linear-gradient(45deg, #b49eca, #ecf0f1);
  color: #8e44ad;
  padding: 15px;
  margin: 10px;
  border-radius: 80% 40% 100% 30% / 90% 50% 70% 35%;
}

.signin-mission {
  background-color: #ecf0f1;
  background: linear-gradient(45deg, #a9c3d4, #ecf0f1);
  color: #3498db;
  font-size: 1rem;
  display: inline;
  padding: 15px;
  margin: 10px;
  border-radius: 80% 40% 100% 30% / 90% 50% 70% 35%;
}

.flipped {
  transform: rotateY(360deg);
}

.signin-page.register-mode .signin {
  transform: rotateY(360deg);
}

.form {
  background-color: white;
  border-radius: 20px;
  width: 80%;
  margin: 30px auto;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}

.input-label {
  font-size: 0.8rem;
  color: #121a22;
  font-weight: 600;
}

.first-label {
  margin-top: 30px;
}

.input-field {
  border: 1px solid #121a22;
  color: #121a22;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  margin-top: -10px;
  outline: none;
}

.signin-input {
  border: 1px solid #8e44ad;
}

.register-input {
  border: 1px solid #3498db;
}

.input-field::placeholder {
  font-style: italic;
  font-size: 0.6rem;
}

.signin-input:focus {
  background-color: #8e44ad;
  border-color: #8e44ad;
  color: #ecf0f1;
}

.register-input:focus {
  background-color: #3498db;
  border-color: #3498db;
  color: #ecf0f1;
}

.form-btn {
  margin: 20px auto;
  color: #ffffff;
  background-color: #3498db;
  padding: 10px;
  border: none;
  border-radius: 10px;
  font-size: 0.9rem;
  font-weight: 700;
  width: 100%;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
}

.register-btn {
  background-color: #3498db !important;
}

.signin-btn {
  background-color:#8e44ad;
}

.signin-text {
  color: #ecf0f1;
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 100px;
  margin-top: -60px;
}

.text-button {
  border: none;
  color: #ffffff;
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 10px;
  animation: vibrate 3s infinite;
  outline: none;
}

.signin-text-btn {
  background-color: #8e44add3;
}

.register-text-btn {
  background-color: #3498dbd3;
}

.register-form {
  gap: 8px;
}

@media (min-width: 1024px) {
  .form {
    width: 50%;
    padding: 20px 80px;
  }

  .input-label {
    font-size: 100%;
  }

  .input-field::placeholder {
    font-size: 80%;
  }
}