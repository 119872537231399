.vision {
  background-color: #ffffff;
  background: linear-gradient(45deg, #b49eca, #ffffff);
  color: #8e44ad;
  padding: 15px;
  border-radius: 80% 40% 100% 30% / 90% 50% 70% 35%;
}

.mission {
  background-color: #ffffff;
  background: linear-gradient(45deg, #a9c3d4, #ffffff);
  color: #3498db;
  padding: 15px;
  border-radius: 80% 40% 100% 30% / 90% 50% 70% 35%;
}

.vision-text {
  padding: 20px;
  border-top: 2px solid #8e44ad;
  border-bottom: 2px solid #8e44ad;
  margin-top: -5px;
  background-color: #8d44ad0c;
  font-weight: 600;
  transition: background-color ease-in-out 1s;
}

.vision-text:hover {
  background-color: #8d44ad4d;
}

.mission-text {
  padding: 20px;
  border-top: 2px solid #3498db;
  border-bottom: 2px solid #3498db;
  margin-top: -5px;
  background-color: #3498db0c;
  font-weight: 600;
  transition: background-color ease-in-out 1s;
}

.mission-text:hover {
  background-color: #3498db4d;
}

@media (min-width: 1024px) {
  .vision-mission {
    display: flex;
    justify-content: space-evenly;
    gap: 10vw;
    margin-top: 50px;
    margin-bottom: 25px;
  }

  .mission {
    font-size: 150%;
  }

  .vision {
    font-size: 150%;
  }
   
  .mission-text {
    width: 35vw;
    line-height: 2rem;
    font-size: 1rem;
  }

  .vision-text {
    width: 35vw;
    line-height: 2rem;
    font-size: 1rem;
  }
}